import React from 'react';
import styled from '@emotion/styled/macro';
import moment from 'moment';
import { colors } from '@styles/ui_palette';
import { Sns } from '@utils/common/footer';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';

// Components
import FooterGnb from '@components/layout/footer/FooterGnb';
import FooterInformation from '@components/layout/footer/FooterInformation';

interface ISns {
    link: string;
    name: string;
}

function Footer() {
    const { t } = useTranslation();
    const snsArr = Sns;

    const CopyNoti = () => {
        Store.addNotification({
            title: t('Notification.succeseTitle'),
            message: t('Notification.copyMessage'),
            type: 'default',
            insert: 'top',
            container: 'top-left',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 1000,
            },
        });
    };

    const handleLink = (sns: ISns) => {
        if (sns.name === 'mail') {
            return;
        }
        window.open(sns.link);
    };
    return (
        <>
            <CustomCenterBox>
                <CustomCenter>
                    <Mail href="mailto:beodix@beodix.com">
                        이메일 문의하기
                        <CustomCenterIcon src="/img/layout/footer/ic_custom_center.svg" />
                    </Mail>
                </CustomCenter>
            </CustomCenterBox>

            <FooterGnb />

            <CopyRightsSection>
                <CopyRightsContainer>
                    <CopyRightsBox>
                        <CompanyInfo>
                            <Company type="text">베오딕스</Company>
                            <Company type="bar">|</Company>
                            <Company type="text">대표 최유화</Company>
                            <Company type="bar">|</Company>
                            <Company type="text">사업자등록번호  363-81-02650</Company>
                            <Company type="bar">|</Company>
                            <Company type="text">
                                대표전화 02-517-5090
                            </Company>
                            <Company type="bar">|</Company>
                            <Company type="text">
                                대표메일주소  beodix@beodix.com
                            </Company>
                        </CompanyInfo>
                        <CompanyInfo>
                            <Company
                                type="text"
                            >
                                서울 강남구 도산대로 509 (청담동, 에스디바이오센서)
                            </Company>
                            <Company type="bar">|</Company>
                            <Company type="text">통신판매업신고번호  제 2023-서울강남-0147호</Company>
                            <Company type="bar">|</Company>
                            <Company type="text">개인정보보호책임자 이성백</Company>
                        </CompanyInfo>
                        <CopyRights>
                            ©
                            {moment().format('YYYY')}
                            COPYRIGHT © ㈜ 베오딕스 Inc. ALL RIGHT RESERVED.
                        </CopyRights>
                    </CopyRightsBox>
                    <SnsBox>
                        {snsArr.map((sns) => (
                            <SnsContain key={sns.name}>
                                {sns.name !== 'mail' && (
                                    <SnsImage
                                        key={sns.name}
                                        src={`/img/layout/footer/ic_${sns.name}.svg`}
                                        onClick={() => handleLink(sns)}
                                    />
                                )}
                                {sns.name === 'mail' && (
                                    <CopyToClipboard
                                        key={sns.name}
                                        text={sns.link}
                                        onCopy={CopyNoti}
                                    >
                                        <SnsImage
                                            key={sns.name}
                                            src={`/img/layout/footer/ic_${sns.name}.svg`}
                                        />
                                    </CopyToClipboard>
                                )}
                            </SnsContain>

                        ))}

                    </SnsBox>
                </CopyRightsContainer>
            </CopyRightsSection>

            <FooterInformation />
        </>
    );
}

const SnsContain = styled.div`
    width: 44px;
    height: 44px;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CopyRightsSection = styled.div`
    margin-top: 85px;
    padding-bottom: 12px;
`;

const CopyRightsContainer = styled.div`
    width: 1600px;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

const CopyRights = styled.div`
    color: ${colors.BlueGray600};
    font-size: 12px;
    font-weight: 300;
`;

const SnsBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const SnsImage = styled('img')`
    width: 25px;
    cursor: pointer;
`;

const CopyRightsBox = styled.div`
    flex: calc(100% - 355px);
`;

const CompanyInfo = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

const Company = styled.div<{type: string, active?: boolean}>`
    margin: ${(props: { type: string; }) => (props.type === 'bar' ? '0 8px' : 0)};
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 300;
    color: ${(props) => (props.active ? colors.Black100 : colors.BlueGray700)};
`;

const CustomCenterBox = styled.div`
    border-bottom: 1px solid ${colors.BlueGray400};
    border-top: 1px solid ${colors.BlueGray400};
`;

const CustomCenter = styled.div`
    width: 1600px;
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Mail = styled.a`
    color: ${colors.Black100};
    font-size: 13px;
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: underline;
    }
`;

const CustomCenterIcon = styled('img')`
    margin-left: 4px;
`;

export default Footer;
