export const CategoryListItem = () => [
    {
        title: '전체',
        active: true,
        type: 'All',
    },
    {
        title: '엔터테인먼트',
        active: false,
        type: '엔터테인먼트',
    },
    {
        title: '웹툰',
        active: false,
        type: '웹툰',
    },
    {
        title: 'S-판타지',
        active: false,
        type: 'S-판타지',
    },
    {
        title: '디지털아이템',
        active: false,
        type: '디지털아이템',
    },
    {
        title: '슈퍼마켓',
        active: false,
        type: '슈퍼마켓',
    },
];

export const ChallengeListItem = () => [
    {
        title: '전체',
        active: true,
        type: '',
    },
    {
        title: '엔터테인먼트',
        active: false,
        type: '엔터테인먼트',
    },
    {
        title: '웹툰',
        active: false,
        type: '웹툰',
    },
    {
        title: 'S-판타지',
        active: false,
        type: 'S-판타지',
    },
    {
        title: '디지털아이템',
        active: false,
        type: '디지털아이템',
    },
    {
        title: '슈퍼마켓',
        active: false,
        type: '슈퍼마켓',
    },
];
