import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { Sns } from '@utils/common/footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { nl2br } from '@utils/help';

interface IServerCheck {
    message: string;
}

interface ISns {
    link: string;
    name: string;
}

function ServerChecked({ message }: IServerCheck) {
    const snsArr = Sns;
    const { t } = useTranslation();

    const CopyNoti = () => {
        Store.addNotification({
            title: t('Notification.succeseTitle'),
            message: t('Notification.copyMessage'),
            type: 'default',
            insert: 'top',
            container: 'top-left',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 1000,
            },
        });
    };

    const handleLink = (sns: ISns) => {
        if (sns.name === 'mail') {
            return;
        }
        window.open(sns.link);
    };

    return (
        <Sections>
            <Container>
                <Title>
                    시스템 점검 중 입니다.
                </Title>
                <EnTitle>
                    This site is under maintenance
                </EnTitle>
                <Description>
                    서비스 이용에 불편을 드려 죄송합니다.
                </Description>
                <MessageBox>
                    <NoticeIcon src="/img/common/ic_notice.svg" />
                    <Messages dangerouslySetInnerHTML={{ __html: nl2br(message) }} />
                </MessageBox>
                <Information>
                    점검의 자세한 내용은 코넛스퀘어 고객센터에서 확인하세요.
                </Information>
                <CustomerButton onClick={() => window.open('https://conuts.zendesk.com/hc/ko')}>
                    고객센터 바로가기
                </CustomerButton>

                <SnsBox>
                    {snsArr.map((sns) => (
                        <SnsContain key={sns.name}>
                            {sns.name !== 'mail' && (
                                <SnsImage
                                    key={sns.name}
                                    src={`/img/common/ic_${sns.name}.svg`}
                                    onClick={() => handleLink(sns)}
                                />
                            )}
                            {sns.name === 'mail' && (
                                <CopyToClipboard
                                    key={sns.name}
                                    text={sns.link}
                                    onCopy={CopyNoti}
                                >
                                    <SnsImage
                                        key={sns.name}
                                        src={`/img/common/ic_${sns.name}.svg`}
                                    />
                                </CopyToClipboard>
                            )}
                        </SnsContain>
                    ))}
                </SnsBox>
            </Container>
        </Sections>
    );
}

const Sections = styled.div`
    background-image: url('/img/common/ic_404_bg.svg');
    width: 1920px;
    height: 1080px;
    background-color: ${colors.Black200};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    width: 434px;
    margin: 0 auto;
`;

const Title = styled.div`
    color: ${colors.White100};
    font-size: 40px;
    font-weight: 500;
`;

const EnTitle = styled.div`
    margin-top: 13px;
    color: ${colors.WarmGray600};
`;

const Description = styled.div`
    color: ${colors.White100};
    font-size: 20px;
    margin-top: 8px;
    margin-bottom: 6px;
`;

const Messages = styled.div`
    color: ${colors.White100};
    font-size: 14px;
`;

const Information = styled.div`
    color: ${colors.White100};
    font-size: 14px;
    margin-top: 40px;
`;

const CustomerButton = styled.div`
    width: 200px;
    height: 58px;
    border: 1px solid ${colors.White100};
    border-radius: 8px;
    color: ${colors.White100};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    cursor: pointer;
`;

const SnsBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 104px;
`;

const SnsImage = styled('img')`
    width: 50px;
    cursor: pointer;
`;

const SnsContain = styled.div``;

const MessageBox = styled.div`
    display: flex;
    align-items: flex-start;
`;

const NoticeIcon = styled('img')`
    margin-right: 8px;
`;

export default ServerChecked;
